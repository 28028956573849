body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100vh;
  /* background: linear-gradient(180deg, #0055ff 0%, rgb(0, 153, 255) 100%); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

code,
pre {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

textarea {
  resize: vertical !important;
}

.sidenavbar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  z-index: 12;
}
.sidebar-container {
  overflow-y: auto;
  height: calc(100vh - 100px);
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.navbutton {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}

.highlightable {
  cursor: pointer;
}

.highlighted {
  background-color:rgb(51, 211, 255);
}